import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const NeuigkeitenHoerl = () => {
  return (
    <Layout backdrop="workshops">
      <Seo
        title="›Hälfte des Lebens‹ in Gebärdensprache"
        description="Wie eine Übersetzung des lautsprachlichen Gedichtes in Gebärdensprache funktioniert und welche Herausforderungen sich dabei ergeben, erfuhren wir bei einem Workshop mit professionellen Gebärdensprachdolmetscherinnen. Unsere Praktikantin Franziska Walter hat ihre Gedanken dazu in einem kurzen Bericht festgehalten."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "›Hälfte des Lebens‹ in Gebärdensprache",
              link: "/neuigkeiten/erfahrungsbericht-gebaerdensprache",
            },
          ]}
        />
        <PageTitle>›Hälfte des Lebens‹ in Gebärdensprache</PageTitle>
        <Heading level={5}>
          Ein Erfahrungsbericht unserer Praktikantin Franziska Walter
        </Heading>
        <Paragraph dropcap={true}>
          Als ich von dem Workshop zu ›Hölderlin in Gebärdensprache‹ hörte, nahm
          ich um ehrlich zu sein ziemlich naiv an, man könne Gedichte einfach
          Wort für Wort, oder zumindest Satz für Satz in Gebärdensprache
          übersetzen. Dass dies nicht so einfach funktioniert, wurde mir jedoch
          im Workshop ziemlich schnell klar.
        </Paragraph>
        <Paragraph>
          Es war bereits der zweite Workshop, den der Hölderlinturm zu diesem
          Thema veranstaltete: Wie im vorausgegangenen Workshop, sollte auch
          diesmal wieder ein Gedicht im Mittelpunkt stehen, das unter Anleitung
          von professionellen Dolmetscher*innen in Gebärdensprache übersetzt
          wird. Diesmal war es Hölderlins Gedicht ›Hälfte des Lebens‹, das 1804
          und somit noch vor Hölderlins Zeit im Tübinger Turm entstand.
        </Paragraph>
        <Paragraph>
          Die Museumsleiterin Sandra Potsch und Krishna-Sara Helmle vom Büro für
          Leichte Sprache moderierten den Workshop. Für die Erarbeitung der
          Übersetzung war die Dolmetscherin Daniela Happ eingeladen, welche
          selbst gehörlos ist. Die beiden Dolmetscherinnen Anne Nagel und Rita
          Mohlau vermittelten zwischen den gehörlosen und hörenden
          Teilnehmer*innen.
        </Paragraph>
        <Paragraph>
          Schon zu Beginn hatten die Teilnehmer*innen viele Fragen mitgebracht:
          Gibt es Dialekte in Gebärdensprache? Gehören die Mundbewegungen zur
          Gebärdensprache mit dazu? Und was ist eigentlich die Gebärde für
          »Tübingen«? Daniela Happ erklärte, dass die Gebärdensprache, wie jede
          lautsprachliche Sprache auch, ihre eigene Kultur hat, in deren
          geschichtlichem und gesellschaftlichem Kontext sie sich entwickelt.
          Welche Schwierigkeiten sich daraus für die Übersetzung von Gedichten
          ergeben, stellten wir sehr bald fest.
        </Paragraph>
        <Paragraph>
          Wenngleich ›Hälfte des Lebens‹ zu den kürzesten und einfachsten unter
          Hölderlins Gedichten zählt, lässt es viel Raum für Interpretation. Mir
          fiel es nicht leicht, mich in das Gedicht hinein zu denken und seinen
          Bedeutungen nachzuspüren. Allerdings stammte meine letzte
          Gedichtinterpretation auch aus der Zeit meines Abis und ist damit
          schon ein paar Jährchen her. Die Teilnehmer*innen diskutierten eifrig
          über die Bedeutung der zwei konträren Strophen. Geht es hier um den
          Verlauf eines Lebens und die Gegenüberstellung von Jugend und Alter?
          Stehen die Schwäne vielleicht für den Dichter, der von der Muse
          geküsst wird? Oder setzt sich das Gedicht weit allgemeiner mit dem
          Verlust von Kultur auseinander?
        </Paragraph>
        <Paragraph>
          Dabei galt es, gemeinsam eine Deutung zu finden, die sowohl dem
          lautsprachlichen Inhalt des Gedichtes gerecht wird, als auch in
          Gebärdensprache funktioniert. Den Musenkuss beispielsweise gäbe es
          unter den Bildern der Gebärdensprache gar nicht, so Daniela Happ.
          Generell lassen sich lautsprachliche Sinnbilder nicht direkt in
          Gebärdensprache übersetzen. Viel besser funktionieren Wörter mit
          konkreten Bedeutungen. Schwierigkeiten bereitete unter anderem auch
          das »heilignüchterne Wasser«. Schon in der Lautsprache ist es schwer
          zu greifen. Wie sollte sich das nun in eine Sprache übersetzen lassen,
          in der solche Wortneuschöpfungen gar nicht existieren? Schließlich
          wurde folgende Interpretation gefunden:
        </Paragraph>
        <Paragraph>
          Ein Dichter befindet sich in einem Schreibrausch. Er ist versunken in
          seine eigene Welt. Man könnte auch sagen, er hat einen ziemlichen
          »Lauf«. Plötzlich stellt er sich vor wie es wäre, wenn er keine
          Inspirationen mehr hätte und nichts mehr zu Papier bringen könne. Ein
          Gefühl von Kälte und Leere durchzieht ihn bei diesem Gedanken. Zuletzt
          erwacht er aus dieser Vorstellung und sitzt wieder am Schreibtisch.
        </Paragraph>
        <Paragraph>
          Mich hat die Übersetzung und Umsetzung des Gedichtes sehr beeindruckt
          und auch emotional berührt. Ich habe bei diesem Workshop sehr viel
          gelernt. Nicht nur, was die Übersetzung und Interpretation eines
          lautsprachlichen Gedichts in Gebärdensprache angeht, sondern ebenso
          Allgemeines über die Gebärdensprache und die damit verbundenen
          Diskurse und Barrieren in unserer Gesellschaft.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoerl
